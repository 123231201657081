import styled, { css } from 'styled-components';
import { Button } from '@uc-common/button';

import { SectionHeading, LinkWithArrow } from '../../Typography';

export const SmallCTAContainer = styled.article`
  background-color: #000000;
`;

export const SmallCTAContent = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px var(--container-gutter-x);
    background: radial-gradient(
        70.23% 124.92% at 41.16% -20.27%,
        rgba(159, 241, 247, 0.106) 0%,
        rgba(241, 127, 182, 0.053) 50%,
        rgba(188, 175, 216, 0) 100%
      )
      #0f1113;
    border-width: 1px 0;
    border-style: solid;
    border-color: #1c2528;
    width: calc(100% + var(--container-gutter-x) * 2);
    border-bottom: none;
    margin-bottom: 0;

    ${tablet()} {
      border-width: 1px;
      border-radius: 12px;
      padding: 75px;
      width: 100%;
      margin-bottom: 80px;
    }

    ${laptop()} {
      padding: 86px;
      margin-bottom: 120px;
    }
  `
);

export const SmallCTAHeading = styled(SectionHeading)`
  line-height: 1.2;
  letter-spacing: -0.025em;
  color: #ffffff;
`;

export const SmallCTALinksContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${tablet()} {
      display: grid;
      grid-template-columns: 187px max-content;
      grid-gap: 25px;
    }

    ${laptop()} {
      margin-top: 20px;
    }
  `
);

export const SmallCTAButton = styled(Button)`
  min-width: 187px;
`;

export const SmallCTALink = styled(LinkWithArrow)(
  ({ theme: { tablet } }) => css`
    margin-top: 35px;

    ${tablet()} {
      margin-top: 0;
    }
  `
);
