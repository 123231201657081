import { pluralize } from './utils';

const round = (number) => Math.ceil(number * 10) / 10;

const ByteUnitEnum = {
  AUTO: 'auto',
  BYTE: 'byte',
  KB: 'kb',
  MB: 'mb',
  GB: 'gb',
  TB: 'tb',
  PB: 'pb',
};

export const prettyBytes = (bytes, unit = ByteUnitEnum.AUTO) => {
  const isAutoMode = unit === ByteUnitEnum.AUTO;

  if (unit === ByteUnitEnum.BYTE || (isAutoMode && bytes < 1000 ** 1)) {
    return pluralize(bytes, 'byte', 'bytes');
  }

  if (unit === ByteUnitEnum.KB || (isAutoMode && bytes < 1000 ** 2)) {
    return `${round(bytes / 1000 ** 1)} KB`;
  }

  if (unit === ByteUnitEnum.MB || (isAutoMode && bytes < 1000 ** 3)) {
    return `${round(bytes / 1000 ** 2)} MB`;
  }

  if (unit === ByteUnitEnum.GB || (isAutoMode && bytes < 1000 ** 4)) {
    return `${round(bytes / 1000 ** 3)} GB`;
  }

  if (unit === ByteUnitEnum.TB || (isAutoMode && bytes < 1000 ** 5)) {
    return `${round(bytes / 1000 ** 4)} TB`;
  }

  return `${round(bytes / 1000 ** 5)} PB`;
};
