import React from 'react';
import { formatMoney } from '@uc-common/utils';

import { isDefined } from '~/src/utils/utils';

export const getMarketingPrice = (price) => {
  if (!isDefined(price)) {
    throw new Error('Price is required');
  }

  if (price === 0) {
    return (
      <p>
        <strong>{`${formatMoney(price, { maximumFractionDigits: 0 })}`}</strong>
      </p>
    );
  }

  // 1 is actual price of custom plan in the backed database
  if (price === 1) {
    return 'Talk to us';
  }

  return (
    <p>
      <strong>{`${formatMoney(price, { maximumFractionDigits: 0 })}`}</strong> / mo
    </p>
  );
};
