export const TRANSFORMATIONS_LIST = [
  {
    title: 'URL API',
    tooltipText:
      'The simplest way to process images on the fly. Each of your images has a unique URL that you can' +
      ' append transformations to. They’re intuitively named, easy to learn, and they happen instantly on our' +
      ' CDN when image versions are requested for delivery.',
  },
  {
    title: 'Content-aware crop and resize',
    tooltipText: 'Detect faces, objects, and corners to determine the perfect cropping frame.',
  },
  {
    title: 'Resize, crop, rotation, flip',
    tooltipText: 'Handy essentials for image manipulation.',
  },
  {
    title: 'Border radius and circle crop',
    tooltipText: 'Make images with rounded corners or as circles.',
  },
  {
    title: 'Effects and enhancements',
    tooltipText: 'Automatically make photos look better or create a distinct look and feel.',
  },
  {
    title: 'Overlays and watermarks',
    tooltipText:
      'Layer images one over another and use intuitive URL operations to set transparency and mutual positioning.',
  },
  {
    title: 'Face-detection based transformations',
    tooltipText:
      'This specialized algorithm returns accurate coordinates of faces in photos, making it easy to' +
      ' automatically crop, blur out, or tag images of people. Use face detection to automatically generate' +
      ' perfectly centered and standardized profile pictures at scale.',
  },
  {
    title: 'Color adjustments',
    tooltipText:
      'Adjust basic image properties (brightness, contrast etc); deliver grayscale, enhanced and inverted colors.',
  },
  {
    title: 'Color profile management',
    tooltipText: 'Accurately convert CMYK to RGB using ICC profile data.',
  },
  {
    title: 'Background removal',
    property: 'remove_bg',
    tooltipText: 'Automatically and accurately isolate key content in any image.',
  },
];
