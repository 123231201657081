import React from 'react';

import {
  PlansScreenSection,
  PlansScreenHeading,
  PlansScreenCardsContainer,
  PlansScreenSubText,
} from './PlansScreen.styles';
import { PlanCard } from './components/PlanCard';

export const PlansScreen = ({ plans = [] }) => (
  <PlansScreenSection as="section" data-navbar-theme="dark">
    <PlansScreenHeading>Free to explore. Pay as you grow.</PlansScreenHeading>
    <PlansScreenSubText>
      Learn and experiment your way. Operations-based pricing fits any usage profile, from static
      content delivery to complex UGC pipelines.
    </PlansScreenSubText>
    <PlansScreenCardsContainer>
      {plans.map(({ id, name, description, price, features }) => {
        return (
          <PlanCard
            key={id}
            name={name}
            description={description}
            price={Number(price)}
            features={features}
          />
        );
      })}
    </PlansScreenCardsContainer>
  </PlansScreenSection>
);
