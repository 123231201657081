import React from 'react';
import { ButtonTypeEnum } from '@uc-common/button';
import { Link } from '../../Link';
import { ReactComponent as TextBubbleSvg } from './assets/text-bubble-icon.svg';
import {
  EnterpriseScreenSection,
  EnterpriseScreenCard,
  EnterpriseScreenTitle,
  EnterpriseScreenTextAndButtonWrapper,
  EnterpriseScreenText,
  EnterpriseScreenFeatures,
  EnterpriseScreenFeature,
  EnterpriseScreenButton,
  EnterpriseScreenButtonContent,
  EnterpriseScreenClients,
} from './styles/EnterpriseScreen.styles';

export const EnterpriseScreen = () => (
  <EnterpriseScreenSection as="section" data-navbar-theme="dark">
    <EnterpriseScreenCard>
      <EnterpriseScreenTitle>Enterprise</EnterpriseScreenTitle>
      <EnterpriseScreenTextAndButtonWrapper>
        <EnterpriseScreenText>
          Built for companies who want to scale with confidence and require advanced security,
          custom pricing, and more.
        </EnterpriseScreenText>
        <EnterpriseScreenButton
          forwardedAs={Link}
          to="/schedule-demo/"
          type={ButtonTypeEnum.PRIMARY}
          data-analytics="btn_enterprise-screen_schedule-demo"
        >
          <EnterpriseScreenButtonContent>
            <TextBubbleSvg />
            Contact Sales
          </EnterpriseScreenButtonContent>
        </EnterpriseScreenButton>
      </EnterpriseScreenTextAndButtonWrapper>

      <EnterpriseScreenFeatures>
        <EnterpriseScreenFeature>Enterprise-grade security</EnterpriseScreenFeature>
        <EnterpriseScreenFeature>HIPAA</EnterpriseScreenFeature>
        <EnterpriseScreenFeature>Custom billing</EnterpriseScreenFeature>
        <EnterpriseScreenFeature>Dedicated solution architect</EnterpriseScreenFeature>
        <EnterpriseScreenFeature>Custom SLA</EnterpriseScreenFeature>
        <EnterpriseScreenFeature>SOC2 Report</EnterpriseScreenFeature>
        <EnterpriseScreenFeature>File size up to 5 TB</EnterpriseScreenFeature>
        <EnterpriseScreenFeature>Private Slack channel</EnterpriseScreenFeature>
      </EnterpriseScreenFeatures>
    </EnterpriseScreenCard>

    <EnterpriseScreenClients title="Trusted by the best developer teams" />
  </EnterpriseScreenSection>
);
