import React, { createContext } from 'react';

import { TooltipContainer, TooltipContent } from './styles/Tooltip.styles';

export const TooltipContext = createContext(null);

export const Tooltip = ({ id, className, tooltipText, children }) => (
  <TooltipContainer className={className} $id={id}>
    <TooltipContext.Provider value={id}>
      {children}
      {Boolean(tooltipText) && (
        <TooltipContent data-tooltip-content={id}>{tooltipText}</TooltipContent>
      )}
    </TooltipContext.Provider>
  </TooltipContainer>
);
