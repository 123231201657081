import React from 'react';

import { toSnakeCase } from '~/src/utils/utils';
import {
  TableHeaderContainer,
  TableHeaderContent,
  TableHeaderLink,
  TableHeaderText,
} from './styles/TableHeader.styles';

export const TableHeader = React.forwardRef(({ plans = [] }, ref) => (
  <TableHeaderContainer ref={ref}>
    <TableHeaderContent>
      {/* layout workaround */}
      <div />
      {plans.map(({ id, name, price }) => {
        const isFreePlan = Number(price) === 0;

        return (
          <TableHeaderLink
            key={id}
            to="/accounts/signup/"
            data-analytics={`btn_plans-compare_signup_${toSnakeCase(name)}`}
          >
            <TableHeaderText>{name}</TableHeaderText>
            <TableHeaderText>{isFreePlan ? 'Get started' : 'Sign up for free'}</TableHeaderText>
          </TableHeaderLink>
        );
      })}
    </TableHeaderContent>
  </TableHeaderContainer>
));
