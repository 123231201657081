import React from 'react';
import { ButtonTypeEnum } from '@uc-common/button';

import {
  SmallCTAHeading,
  SmallCTAContainer,
  SmallCTAButton,
  SmallCTALink,
  SmallCTALinksContainer,
  SmallCTAContent,
} from './styles/SmallCTA.styles';

import { SectionWrapper } from '../CommonStyles';
import { Link } from '../Link';

export const SmallCTA = ({ ppc = false, btnSignUpProps, btnScheduleDemoProps }) => (
  <SmallCTAContainer data-navbar-theme="dark">
    <SectionWrapper>
      <SmallCTAContent>
        <SmallCTAHeading>Start building for&nbsp;free</SmallCTAHeading>
        <SmallCTALinksContainer>
          <SmallCTAButton
            forwardedAs={Link}
            type={ButtonTypeEnum.PRIMARY}
            to="/accounts/signup/"
            {...btnSignUpProps}
          >
            Get started
          </SmallCTAButton>
          {!ppc && (
            <SmallCTALink to="/schedule-demo/" {...btnScheduleDemoProps}>
              Questions? Talk to us
            </SmallCTALink>
          )}
        </SmallCTALinksContainer>
      </SmallCTAContent>
    </SectionWrapper>
  </SmallCTAContainer>
);
