import { TRANSFORMATIONS_LIST } from './transformations-list';
import { PLANS_ENUM } from '../../data/data';

export const FEATURES_SECTIONS = [
  {
    name: 'Uploading',
    features: [
      {
        title: 'File uploader',
        tooltipText:
          'Flagship uploading GUI designed to be user friendly. It’s a responsive HTML5 widget that fits' +
          ' your stack and branding. It’s easy to integrate. You can customize basic design via dashboard, or' +
          ' go deep with CSS & JavaScript.',
      },
      {
        title: 'Upload API',
        tooltipText:
          'The Upload API allows uploading files to Uploadcare servers. The API can be used with our File Uploader' +
          ' HTML5 widget, or via direct integration.',
      },
      {
        title: 'Accelerated uploading',
        tooltipText:
          'Use your own domain for CDN links to your files stored on Uploadcare. Replace the default' +
          ' ucarecdn.com domain with e.g. cdn.mycompany.com for your URLs.',
      },
      {
        title: 'Malware protection',
        tooltipText:
          'Your uploads are continually scanned for infected or malicious files using ClamAV, a high-performance' +
          ' antivirus engine. On business plan you can choose what to do with infected files.',
      },
      {
        title: 'Signed uploads',
        property: 'secure_uploads',
        tooltipText:
          'Control who can upload files to your account by authenticating upload requests. You generate a token' +
          ' on your backend that your app will pass to File Uploader or API.',
      },
      {
        title: 'Image editor',
        tooltipText:
          'Help your users upload better images. The File Uploader comes with a GUI-based image editor, featuring' +
          ' 9 built-in operations and a sleek, intuitive design.',
      },
      {
        title: 'File size limiting',
        tooltipText: 'Set the maximum file size for uploaded files on the server side.',
      },
      {
        title: 'MIME type filtering',
        property: 'mime_type_filtering',
        tooltipText: 'Restrict the types of files that can be uploaded to your project.',
      },
      {
        title: 'Copy uploads to custom S3 bucket',
        property: 'custom_storage',
        tooltipText: 'Connect your S3 bucket to Uploadcare project.',
      },
      {
        title: 'Backup to S3 bucket',
        property: 'backup',
        tooltipText: 'Use your own S3 bucket to backup files from your Uploadcare projects.',
      },
      {
        title: 'Upload widget whitelabeling',
        property: 'white_labeling',
        tooltipText:
          'Remove copyright from the Uploader, use custom CNAMEs with CDN, upload API and social sources.',
      },
      {
        title: 'Custom OAuth apps',
        property: 'custom_oauth_apps',
        tooltipText:
          'Users will see your app name, icon, and legal documents when authenticating with Facebook, Google Drive,' +
          ' Dropbox etc. to upload files.',
      },
      {
        title: 'Custom Upload API domain',
        property: 'custom_upload_cnames',
        tooltipText:
          'Use your own domain name for the Upload API and source endpoints, e.g. upload.mydomain.com instead of upload.uploadcare.com and social.uploadcare.com',
      },
    ],
  },
  {
    name: 'Optimization',
    features: [
      {
        title: 'URL API',
        tooltipText:
          'The simplest way to process images on the fly. Each of your images has a unique URL that you can' +
          ' append transformations to. They’re intuitively named, easy to learn, and they happen instantly on our' +
          ' CDN when image versions are requested for delivery.',
      },
      {
        title: 'Automatic responsive images',
        tooltipText: 'Automatically load the optimal images for each breakpoint and screen size.',
      },
      {
        title: 'Automatic image optimization',
        tooltipText:
          'Let our AI analyze an image and set the correct compression level just before visual artifacts appear.',
        featureEnabledPlans: [PLANS_ENUM.PRO, PLANS_ENUM.BUSINESS],
      },
      {
        title: 'Automatic format selection',
        tooltipText:
          'Give modern browsers the modern image formats they need: smaller and snappier to load. Convert' +
          ' images on the fly into WebP, AVIF, PNG, or JPEG, or ask Uploadcare to select the best format' +
          ' automatically based on each user’s browser support.',
        featureEnabledPlans: [PLANS_ENUM.PRO, PLANS_ENUM.BUSINESS],
      },
      {
        title: 'Animated images optimization',
        tooltipText: 'Convert animated GIF, WebP or HEIC to video on the fly.',
        featureEnabledPlans: [PLANS_ENUM.PRO, PLANS_ENUM.BUSINESS],
      },
      {
        title: 'WebP',
        tooltipText:
          'Modern and efficient format that supports transparency. Available for both automatic and manual' +
          ' format conversion.',
        featureEnabledPlans: [PLANS_ENUM.PRO, PLANS_ENUM.BUSINESS],
      },
      {
        title: 'AVIF',
        property: 'avif_image_optimization',
        tooltipText:
          'A new highly efficient image format which beats JPEG at almost 50% of file size with similar' +
          ' visual quality.',
      },
    ],
  },
  {
    name: 'Image transformations',
    features: TRANSFORMATIONS_LIST,
  },
  {
    name: 'Delivery',
    features: [
      {
        title: 'High performance CDN',
        property: 'adaptive_delivery',
        tooltipText:
          '325,000+ CDN nodes in 135 countries greatly increase loading speed and reduce latency from anywhere' +
          ' in the world.',
      },
      {
        title: 'Fetch and deliver remote files',
        tooltipText:
          'Use Uploadcare CDN with your existing assets — just add a prefix to the URLs and your files will be distributed from our CDN.',
      },
      {
        title: 'Search engine indexing control',
        tooltipText:
          'If you turn off indexing, Uploadcare adds response headers to restrict indexing of images and text,' +
          ' making sure your files don’t turn up in search or impact your overall SEO.',
      },
      {
        property: 'secure_delivery',
        tooltipText:
          'Grant access to specific files or file versions. Signed URLs contain authentication tokens' +
          ' that give access only ones with the link. Requires CDN CNAME.',
      },
      {
        title: 'Custom CDN / Proxy CNAME',
        property: 'custom_cdn_cnames',
        tooltipText:
          'If you depend on a specific CDN infrastructure or are under contract, you can use your current CDN' +
          ' with our platform.',
      },
    ],
  },
  {
    name: 'File management',
    features: [
      {
        title: 'API for everything',
        property: 'uploads',
        tooltipText:
          'The Upload API allows uploading files to Uploadcare servers. The API can be used with our File Uploader' +
          ' HTML5 widget, or via direct integration.',
      },
      {
        title: 'Access logs',
        tooltipText:
          'Review all API requests (POST, GET, PUT, DELETE), errors, and infected files in your Dashboard.',
      },
      {
        property: 'webhooks',
        tooltipText:
          'Subscribe to file events and trigger corresponding actions within your app. For example, get' +
          ' a notification when a file is uploaded to a public URL you specify.',
      },
      {
        title: 'Zapier integration',
        tooltipText:
          'Build no-code apps with Uploadcare. Send notifications about new files, copy files to' +
          ' Google Drive or Dropbox.',
      },
      {
        title: 'Make integration',
        tooltipText: 'Make API calls, upload files or listen for webhooks with no code.',
      },
      {
        title: 'Usage Info',
        tooltipText:
          'Let your app know your projects usage. For example, to calculate the bills for your customers.',
      },
      {
        title: 'Image auto-tagging',
        tooltipText: 'Categorize and tag people, objects, and other content in your images.',
      },
      {
        title: 'Unsafe content detection',
        property: 'mime_type_filtering',
        tooltipText: 'Detect and block unwanted content like violence, porn or drug use.',
      },
      {
        title: 'Video processing',
        property: 'video_conversion',
        tooltipText: 'Encode video files, adjust quality, generate thumbnails.',
      },
      {
        title: 'Document conversion',
        property: 'document_conversion',
        tooltipText: 'Convert documents from all popular formats.',
      },
      {
        title: 'Color recognition',
        tooltipText:
          'Get the prevailing colors in an image. Specify the number of colors you want and recieve a JSON with RGB' +
          ' values. Use it to, for example, build color-based search filters.',
        featureEnabledPlans: [PLANS_ENUM.PRO, PLANS_ENUM.BUSINESS],
      },
    ],
  },
];
