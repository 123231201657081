import { formatNumber, formatMoney } from '@uc-common/utils';

import { isDefined } from '~/src/utils/utils';
import { prettyBytes } from '~/src/utils/formatting';

const LIMITS_ENUM = {
  OPERATIONS: 'operations',
  TRAFFIC: 'traffic',
  STORAGE: 'storage',
};

const LIMITS_LIST = new Set([LIMITS_ENUM.OPERATIONS, LIMITS_ENUM.TRAFFIC, LIMITS_ENUM.STORAGE]);

// Utils that are used in pricing related stuff
export const getLimitValue = (limitName, limitValue) => {
  if (!isDefined(limitName) || !isDefined(limitValue)) {
    throw new Error('Limit name and value are required');
  }

  return limitName === 'byte' ? prettyBytes(limitValue) : formatNumber(limitValue);
};

export const getTierLimit = (index, limit, tiers, unit) => {
  const previousTierLimit = index > 0 ? tiers[index - 1].limit : 0;
  const limitDiff = limit === null ? previousTierLimit : limit - previousTierLimit;

  const limitDisplayValue = getLimitValue(unit, limitDiff);

  switch (index) {
    case 0:
      return `First ${limitDisplayValue}`;
    case tiers.length - 1:
      return `Over ${limitDisplayValue}`;
    default:
      return `Next ${limitDisplayValue}`;
  }
};

export const getLimitPrice = (price, unit) => {
  if (!isDefined(price)) {
    throw new Error('Price is required');
  }

  const pricePerUnit = unit === 'upload unit' ? price / 1000 : price;

  return price === 0 ? 'Free' : formatMoney(pricePerUnit, { maximumFractionDigits: 4 });
};

export const getPlanLimits = (features) => {
  if (!isDefined(features)) {
    throw new Error('Features are required');
  }

  return Array.from(LIMITS_LIST)
    .map((limitName) => {
      const limit = features[limitName] ?? {};

      return limit.is_enabled
        ? {
            ...limit,
            name: limitName,
          }
        : null;
    })
    .filter(Boolean);
};

export const getPlanAddons = (features) => {
  if (!isDefined(features)) {
    throw new Error('Features are required');
  }

  return Object.keys(features)
    .filter((f) => features[f].is_enabled && features[f].is_addon)
    .map((enabledAddon) => features[enabledAddon]);
};

export const getFeaturePrice = (feature) => {
  if (!isDefined(feature)) {
    throw new Error('Addon is required');
  }

  const {
    price,
    extra_price: extraPrice,
    is_enabled: isEnabled,
    is_gauge: isMonthly,
    unit_name: unitName,
  } = feature;

  if (!isEnabled) {
    return null;
  }

  if (Number(price)) {
    return formatMoney(price);
  }

  if (Number(extraPrice)) {
    return `${formatMoney(extraPrice, {
      maximumFractionDigits: 0,
    })} / ${isMonthly ? 'month' : unitName}`;
  }

  return 'Custom';
};

export const getFeatureName = (featureProperty, plan) => {
  if (!isDefined(featureProperty) || !isDefined(plan)) {
    throw new Error('Feature accessor and plan are required');
  }

  const feature = plan.features[featureProperty];

  if (!feature) {
    throw new Error(`Feature ${featureProperty} is not found in plan`);
  }

  return feature.title;
};

export const getMarketingUnit = (unit, normalizedUnit = unit) => {
  if (!isDefined(unit)) {
    throw new Error('Unit name is required');
  }

  return unit === 'upload unit' ? 'unit' : normalizedUnit;
};

export const getTeamMembers = (feature) => {
  if (!isDefined(feature)) {
    throw new Error('Team members feature data is required');
  }

  if (!feature.is_enabled) {
    return 1;
  }

  if (feature.limit === null) {
    return 'Custom';
  }

  if (feature.limit === 1) {
    return 'Personal use only';
  }

  /* we're adding one because it's the project's owner */
  return `${feature.limit + 1} seats`;
};
