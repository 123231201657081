import styled, { css } from 'styled-components';
import { Container as Wrapper } from '@uc-common/container';

import { Tooltip, TooltipButton, TooltipContent } from '~/src/ui/Tooltip';

export const PlansComparisonScreenSection = styled(Wrapper)(
  ({ theme: { tablet } }) => css`
    padding-top: 60px;

    ${tablet()} {
      padding-top: 75px;
    }
  `
);

export const PlansComparisonScreenHeading = styled.h2(
  ({ theme: { tablet, laptop } }) => css`
    text-align: center;
    color: #ffffff;
    max-width: 750px;
    margin: 0 auto 40px;

    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.02em;

    ${tablet()} {
      font-size: 44px;
    }

    ${laptop()} {
      margin-bottom: 50px;
      font-size: 54px;
    }
  `
);

export const PlansComparisonTable = styled.div(
  ({ theme: { tablet } }) => css`
    width: 100%;
    overflow: auto;

    ${tablet()} {
      overflow: visible;
    }
  `
);

export const FeatureSection = styled.div`
  width: 100%;
  min-width: 700px;
`;

export const FeatureSectionTitle = styled.span`
  display: block;
  width: 100%;
  padding: 18px 10px;
  border-bottom: 1px solid #1a1e24;
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
`;

export const FeatureSubsection = styled.div`
  display: grid;
  width: 100%;
`;

export const FeatureSubsectionSubtitle = styled.span`
  display: block;
  width: 100%;
  padding: 12px 10px;
  border-bottom: 1px solid #1a1e24;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
`;

export const TableRowContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 12fr 10fr 10fr 10fr;
  grid-gap: 15px;
  padding: 10px;
  border-bottom: 1px solid #1a1e24;

  ${TooltipContent} {
    top: calc(100% + 3px);
    left: -10px;
    width: 330px;
    max-width: none;
    background-color: #111113;
    color: #bbc4d2;
    border: 1px solid #21262f;
    border-radius: 12px;
    font-size: 15px;
    z-index: 10;

    &::before {
      display: none;
    }
  }
`;
export const TableRowTitle = styled(Tooltip)`
  font-size: 16px;
  color: #919eaa;

  ${TooltipContent} {
    margin-left: 10px;
  }
`;

export const TableRowTitleTooltipButton = styled(TooltipButton)`
  margin-left: 0;
  margin-right: 12px;
`;

export const FeatureForPlan = styled.div`
  font-size: 16px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CheckmarkIcon = styled.svg`
  color: #25ad3b;
  width: 24px;
  height: 24px;
`;
export const FeatureTooltip = styled(Tooltip)`
  width: 100%;
  justify-content: center;

  ${TooltipContent} {
    width: fit-content;
  }
`;

export const FeatureTooltipButton = styled(TooltipButton)`
  margin-left: 8px;
`;

export const TableWrapper = styled.div`
  width: 100%;
`;
