import styled, { css } from 'styled-components';
import { Container as Wrapper } from '@uc-common/container';
import { Button } from '@uc-common/button';
import { Clients } from '~/src/components/Clients';

export const EnterpriseScreenSection = styled(Wrapper)(
  ({ theme: { tablet } }) => css`
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${tablet()} {
      padding-top: 75px;
    }
  `
);

export const EnterpriseScreenCard = styled.div(
  ({ theme: { tablet } }) => css`
    background-color: #111113;
    border-radius: 16px;
    max-width: 715px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 35px;

    ${tablet} {
      padding: 60px 80px;
    }
  `
);

export const EnterpriseScreenTitle = styled.h3`
  font-size: 26px;
  color: #ffffff;
  line-height: 1.3;
  text-align: left;
`;

export const EnterpriseScreenTextAndButtonWrapper = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-gap: 30px;
    padding-top: 15px;

    ${tablet()} {
      grid-template-columns: auto auto;
      grid-gap: 40px;
    }

    ${laptop()} {
      grid-gap: 55px;
    }
  `
);

export const EnterpriseScreenText = styled.p(
  ({ theme: { desktop } }) => css`
    font-size: 15px;
    color: #ffffff;
    line-height: 1.6;
    text-align: left;

    ${desktop()} {
      font-size: 16px;
    }
  `
);

export const EnterpriseScreenFeatures = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  position: relative;
  left: -10px;
`;

export const EnterpriseScreenFeature = styled.div`
  background-color: #191b1d;
  border: 1px solid #24252d;
  border-radius: 100px;
  font-size: 14px;
  line-height: 1.4;
  color: #717c85;
  padding: 5px 20px;
  margin-top: 10px;
  margin-left: 10px;
`;

export const EnterpriseScreenButton = styled(Button)`
  padding-left: 25px;
  padding-right: 25px;
`;

export const EnterpriseScreenButtonContent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 12px;
`;

export const EnterpriseScreenClients = styled(Clients)(
  ({ theme: { tablet, laptop } }) => css`
    width: 100%;
    margin-top: 40px;

    ${tablet()} {
      margin-top: 60px;
    }

    ${laptop()} {
      margin-top: 80px;
    }
  `
);
