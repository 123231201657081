import React from 'react';

import { getFeaturePrice, getFeatureName } from '~/src/components/forPricing/utils/utils';
import {
  FeatureForPlan,
  TableRowContent,
  TableRowTitle,
  TableRowTitleTooltipButton,
  CheckmarkIcon,
  FeatureTooltip,
  FeatureTooltipButton,
} from '../../styles/PlansComparisonScreen.styles';

export const FeatureRow = ({
  sectionName,
  title,
  property,
  plans = [],
  tooltipText,
  featureEnabledPlans = [],
}) => {
  const isHardcoded = !property;
  const FeatureName = title ? title.replace(/[\s.,%/]/g, '_').toLowerCase() : property;
  const SectionName = sectionName.replace(/[\s.,%/]/g, '_').toLowerCase();

  const getFeatureKey = (name) => {
    return `${SectionName}-${FeatureName}-${name}`;
  };

  const showCheckmark = (name, isEnabled) => {
    if (featureEnabledPlans.length) return featureEnabledPlans.includes(name);
    return isEnabled || isHardcoded;
  };

  return (
    <TableRowContent>
      <TableRowTitle id={`${SectionName}-${FeatureName}-tooltip`} tooltipText={tooltipText}>
        <TableRowTitleTooltipButton />
        {title ?? getFeatureName(property, plans[0])}
      </TableRowTitle>

      {plans.map(({ features, name }) => {
        const {
          is_enabled: isEnabled,
          extra_price: extraPrice,
          soft_limit: softLimit,
        } = features[property] ?? {};

        const priceToDisplay = extraPrice && getFeaturePrice(features[property]);
        const FeatureKey = getFeatureKey(name);

        return (
          <FeatureForPlan key={FeatureKey}>
            {softLimit === 1 ? (
              <FeatureTooltip id={`${FeatureKey}-tooltip`} tooltipText={`Then $${extraPrice} each`}>
                1 included
                <FeatureTooltipButton />
              </FeatureTooltip>
            ) : (
              priceToDisplay ||
              (showCheckmark(name, isEnabled) && (
                <CheckmarkIcon
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <use xlinkHref="#circle-checkmark" />
                </CheckmarkIcon>
              ))
            )}
          </FeatureForPlan>
        );
      })}
    </TableRowContent>
  );
};
