import { useEffect } from 'react';

export const useSyncScroll = (firstRef, secondRef) => {
  if (!firstRef || !secondRef) {
    throw new Error('Refs are required');
  }

  useEffect(() => {
    const firstElement = firstRef.current;
    const secondElement = secondRef.current;

    if (!firstElement || !secondElement) return;

    let ticking = false;

    const syncScroll = (e) => {
      if (ticking) return;

      const { target } = e;

      requestAnimationFrame(() => {
        target === firstElement
          ? (secondElement.scrollLeft = firstElement.scrollLeft)
          : (firstElement.scrollLeft = secondElement.scrollLeft);
        ticking = false;
      });
    };

    firstElement.addEventListener('scroll', syncScroll);
    secondElement.addEventListener('scroll', syncScroll);

    return () => {
      firstElement.removeEventListener('scroll', syncScroll);
      secondElement.removeEventListener('scroll', syncScroll);
    };
  }, [firstRef, secondRef]);
};
