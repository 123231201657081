import styled from 'styled-components';
import { Button } from '@uc-common/button';
import { Tooltip, TooltipButton, TooltipContent, TooltipHoverZone } from '~/src/ui/Tooltip';
import { LimitsInfoContainer } from '~/src/components/forPricing/PlansScreen/components/PlanCard/components/LimitsInfo/styles/LimitsInfo.styles';
import { FeaturesList } from '../components/FeaturesList';

export const PlanCardContainer = styled.div`
  position: relative;
  background-color: #111113;
  border: 1px solid #21262f;
  border-radius: 16px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #18181d;
  }
`;

export const PlanCardHeading = styled.h3`
  font-size: 14px;
  color: #858f99;
  line-height: 1.3;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
`;

export const PlanCardButton = styled(Button)`
  width: 100%;
  margin-top: 24px;
  font-weight: 400;
`;

export const PlanCardParagraph = styled.p`
  margin-top: 15px;
  font-size: 15px;
  color: #ffffff;
  line-height: 1.6;
  text-align: left;
`;

export const PlansCardList = styled(FeaturesList)`
  margin-top: 20px;
  grid-gap: 10px;
  color: #bbc4d2;
  line-height: 1;
  font-size: 14px;
  margin-bottom: 35px;
`;

export const PlanCardSticker = styled.span`
  position: absolute;
  top: 14px;
  right: 14px;
  padding: 4px 18px;
  background-color: #14260d;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  color: #4f942e;
`;

export const PlanCardLimitsInfo = styled(LimitsInfoContainer)`
  color: #717c85;
  line-height: 1.8;
`;

export const PlansCardListTitle = styled.h5`
  font-size: 13px;
  color: #717c85;
  line-height: 1.3;
  text-align: left;
  margin-top: 35px;
  font-weight: 500;
`;

export const PlanCardBottom = styled.div`
  flex-direction: column;
  border-top: 1px solid #21262f;
  padding-top: 25px;
  margin-top: auto;
`;

export const PlanCardPriceWrapper = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
  color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  strong {
    font-size: 28px;
  }
`;

export const PlanCardTooltipButton = styled(TooltipButton)`
  width: 16px;
  height: 16px;
`;

export const PlanCardTooltip = styled(Tooltip)`
  font-size: inherit;
  color: inherit;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${TooltipContent} {
    top: auto;
    bottom: calc(100% + 10px);
    width: calc(100% + 40px);
    left: -20px;
    max-width: none;
    background-color: #2e3136;
    color: #8a9299;
    border: 1px solid #3f444d;
    border-radius: 12px;
    font-size: 13px;
    z-index: 10;
    padding: 20px;

    &::before {
      display: none;
    }
  }

  &:hover ${PlanCardTooltipButton} {
    color: var(--icon-active-color, #30333d);
  }
`;

export const PlanCardTooltipHeading = styled.h5`
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const PlanCardTooltipHoverZone = styled(TooltipHoverZone)`
  flex-direction: row;
  justify-content: flex-start;

  p {
    border-bottom: 1px dotted #8a9299;
    display: inline;
    line-height: 1.6;
  }
`;
