export const TOOLTIPS_CONTENT = new Map([
  [
    'virus_checking',
    'Your uploads are continually scanned for infected or malicious files using ClamAV, a high-performance' +
      ' antivirus engine.',
  ],
  [
    'aws_rekognition_detect_labels',
    'Tag people, objects and other content classes in your images, with accurate confidence levels. ',
  ],
  [
    'remove_bg',
    'Remove backgrounds with chilling accuracy with remove.bg: the industry standard in background removal AI.',
  ],
  [
    'video_conversion',
    'Encode video files from all popular formats to MP4, WEBM, OGG, and even GIFs. Adjust quality, dimensions, cut out sections, generate thumbnails and more.',
  ],
  [
    'document_conversion',
    'Rule the office by ruling the entire office suite, with reliable conversion between popular Microsoft, Apple,' +
      ' OpenDocument, text, data and even Adobe file formats. Most docs can be converted to images, too.',
  ],
  [
    'custom_cdn_cnames',
    'Use your own domain for CDN links to your files stored on Uploadcare. Replace the default ucarecdn.com domain' +
      ' with e.g. cdn.mycompany.com for your URLs.',
  ],
  [
    'custom_upload_cnames',
    'Use your own domain name for the Upload API and source endpoints, e.g. upload.mydomain.com instead of' +
      ' upload.uploadcare.com.',
  ],
  [
    'avif_image_optimization',
    'A new highly efficient image format which beats JPEG at almost 50% of file size with similar' +
      ' visual quality.',
  ],
]);
