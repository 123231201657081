import React, { useCallback, useRef, useState } from 'react';
import useComponentSize from '@rehooks/component-size';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';

import { ReactComponent as IconPlus } from '@static/icons/icon-plus.svg';
import { FAQ_CONTENT } from './FAQContent';

const Heading = styled.h2(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    line-height: 1.2;
    text-align: center;

    ${tablet()} {
      font-size: 44px;
    }
    ${laptop()} {
      font-size: 54px;
    }
  `
);

const StyledIndex = styled.div`
  transition: color 0.3s;
`;

const StyledCross = styled(IconPlus)`
  transition:
    opacity 0.3s,
    transform 0.6s;
  color: #ffffff;
  opacity: 0.3;
  width: 17px;
  height: 17px;

  ${({ $isActive }) =>
    $isActive
      ? css`
          transform: rotate(-135deg);
        `
      : css`
          transform: rotate(0deg);
        `}
`;

const StyledQuestion = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    transition: color 0.3s;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
    flex: 1;

    ${tablet()} {
      font-size: 18px;
    }

    ${laptop()} {
      font-size: 20px;
      line-height: 26px;
    }

    ${({ $isActive }) =>
      $isActive
        ? css`
            color: #ffffff;
            opacity: 1;
          `
        : css`
            color: #afb6c0;
          `}
  `
);

const StyledHeader = styled.div`
  cursor: pointer;
  &:hover {
    ${StyledQuestion} {
      color: #ffffff;
    }
    ${StyledIndex} {
      color: #596570;
    }
    ${StyledCross} {
      opacity: 1;
    }
  }
`;

const Item = ({ idx, question, answer, onClick, isActive }) => {
  const handleClick = useCallback(() => onClick(idx), [idx, onClick]);
  const answerRef = useRef();
  const { height } = useComponentSize(answerRef);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        borderTop: '1px #21262F solid',
      }}
    >
      <Box as={StyledHeader} onClick={handleClick} sx={{ display: 'flex', width: '100%' }}>
        <Text
          as={StyledIndex}
          sx={{
            fontSize: '16px',
            lineHeight: '1.4em',
            display: ['none', 'flex'],
            alignItems: 'center',
            width: ['0px', '87px', '118px', '140px'],
            color: '#737A83',
          }}
        >
          {`00${idx + 1}`.substr(-2, 2)}
        </Text>

        <StyledQuestion $isActive={isActive}>{question}</StyledQuestion>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: '12px',
            py: '18px',
          }}
        >
          <StyledCross $isActive={isActive} />
        </Box>
      </Box>
      <Box
        sx={{
          ml: ['0px', '87px', '118px', '140px'],
          transition: 'height .3s ease-in-out, opacity .3s ease-in-out',
        }}
        style={{
          height: isActive ? height : 0,
          opacity: isActive ? 1 : 0,
        }}
      >
        <Text
          ref={answerRef}
          sx={{
            fontSize: '16px',
            lineHeight: '1.8',
            maxWidth: 700,
            paddingBottom: '40px',
            color: '#BBC4D2',
            a: { color: '#BBC4D2', transition: 'color 0.25s' },
            'a:hover': { color: '#ffffff' },
          }}
        >
          {answer}
        </Text>
      </Box>
    </Box>
  );
};

const Accordeon = ({ children }) => {
  const [activeIdx, setActiveIdx] = useState(null);

  const handleClick = useCallback(
    (idx) => setActiveIdx(idx === activeIdx ? null : idx),
    [activeIdx]
  );

  return (
    <Box
      sx={{
        width: '100%',
        borderBottom: '1px #21262F solid',
      }}
    >
      {React.Children.map(children, (child, idx) => (
        <>
          {React.cloneElement(child, {
            idx,
            isActive: idx === activeIdx,
            onClick: handleClick,
          })}
        </>
      ))}
    </Box>
  );
};

export const Faq = () => (
  <Flex
    sx={{
      width: '100%',
      justifyContent: 'center',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          mb: [40, 40, 50, 50],
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Heading>Frequently asked questions</Heading>
      </Box>
      <Accordeon>
        {FAQ_CONTENT.map(({ question, answer }) => (
          <Item
            key={question}
            question={question}
            answer={<div dangerouslySetInnerHTML={{ __html: answer }} />}
          />
        ))}
      </Accordeon>
    </Box>
  </Flex>
);
