import { getLimitValue, getPlanLimits } from '~/src/components/forPricing/utils';

export const getLimitsInfo = ({ features }) => {
  const limits = getPlanLimits(features).map(
    ({
      name,
      hard_limit: hardLimit,
      soft_limit: softLimit,
      unit_name: unitName,
      is_gauge: isGauge,
    }) => {
      return `${getLimitValue(unitName, hardLimit ?? softLimit)} ${
        isGauge ? '' : 'monthly '
      }${name}`;
    }
  );

  return limits.join(', ');
};
