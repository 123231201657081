import React, { useContext } from 'react';

import { TooltipContext } from '../Tooltip';
import { TooltipHoverZoneContainer } from '../styles/Tooltip.styles';

export const TooltipHoverZone = ({ className, children }) => {
  const id = useContext(TooltipContext);

  return (
    <TooltipHoverZoneContainer className={className} data-tooltip-button={id}>
      {children}
    </TooltipHoverZoneContainer>
  );
};
