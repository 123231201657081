import styled, { css } from 'styled-components';
import { Container as Wrapper } from '@uc-common/container';

export const FAQScreenArticle = styled(Wrapper)(
  ({ theme: { tablet, laptop } }) => css`
    padding: 90px 0 150px;

    ${tablet()} {
      padding: 85px 0 160px;
    }

    ${laptop()} {
      padding: 110px 0 200px;
    }
  `
);
