import styled, { css } from 'styled-components';
import { ReactComponent as InfoIcon } from '@static/icons/info.svg';

export const TooltipContent = styled.div`
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  position: absolute;
  top: calc(100% + 13px);
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  color: #000000;
  border-radius: 6px;
  padding: 14px 20px;
  max-width: 100%;
  z-index: 90;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.09)) drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.17));

  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 0;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #ffffff transparent;
    border-style: solid;
  }
`;

export const TooltipButtonCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  margin: 0;
  cursor: pointer;
`;

export const TooltipButtonIcon = styled(InfoIcon)`
  transition: color 0.2s ease-in-out;
`;

export const TooltipStyledButton = styled.button.attrs({
  type: 'button',
})`
  position: relative;
  background: none;
  border: none;
  width: var(--icon-size, 20px);
  height: var(--icon-size, 20px);
  color: var(--icon-color, #1d1f25);
  padding: 0;
  margin-left: 0.3em;
  font-size: inherit;
  flex-shrink: 0;

  &:hover,
  &:has(:checked) {
    color: var(--icon-active-color, #30333d);
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
  }
`;

export const TooltipContainer = styled.div(
  ({ $id = '' }) => css`
    position: relative;
    display: inline-flex;
    align-items: center;

    [data-tooltip-button='${$id}']:has(:checked) + [data-tooltip-content='${$id}'] {
      opacity: 1;
      pointer-events: auto;
    }

    @media (hover: hover) and (pointer: fine) {
      [data-tooltip-button='${$id}']:hover + [data-tooltip-content='${$id}'] {
        opacity: 1;
        pointer-events: auto;
      }
    }
  `
);

export const TooltipHoverZoneContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
