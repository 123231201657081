import React from 'react';
import { BlueLink } from '~/src/components/Typography';
import {
  FeatureForPlan,
  TableRowContent,
  TableRowTitle,
  TableRowTitleTooltipButton,
} from '~/src/components/forPricing/PlansComparisonScreen/styles/PlansComparisonScreen.styles';
import { PLANS_ENUM } from '~/src/components/forPricing/data';

export const SupportRow = ({ sectionName, title, tooltipText, plans = [] }) => {
  const FeatureName = title.replace(/[\s.,%/]/g, '_').toLowerCase();
  const SectionName = sectionName.replace(/[\s.,%/]/g, '_').toLowerCase();

  const getFeatureKey = (name) => {
    return `${SectionName}-${FeatureName}-${name}`;
  };

  return (
    <TableRowContent>
      <TableRowTitle id={`${FeatureName}-tooltip`} tooltipText={tooltipText}>
        <TableRowTitleTooltipButton />
        {title}
      </TableRowTitle>

      {plans.map(({ name }) => {
        const isBusinessPlan = name === PLANS_ENUM.BUSINESS;

        const FeatureKey = getFeatureKey(name);

        return (
          <FeatureForPlan key={`${FeatureKey}`}>
            {isBusinessPlan && (
              <BlueLink to="/schedule-demo/" data-analytics={`btn_support-section_${FeatureName}`}>
                Contact us
              </BlueLink>
            )}
          </FeatureForPlan>
        );
      })}
    </TableRowContent>
  );
};
