import React from 'react';
import { Container as Wrapper } from '@uc-common/container';

import { FAQScreenArticle } from './styles/FAQScreen.styles';
import { Faq } from './components/FAQ';

export const FAQScreen = () => (
  <FAQScreenArticle as="section" data-navbar-theme="dark">
    <Wrapper>
      <Faq />
    </Wrapper>
  </FAQScreenArticle>
);
