import styled, { css } from 'styled-components';

import { Link } from '~/src/components/Link';
import { hideScrollbarStyles } from '~/src/components/CommonStyles';

export const TableHeaderContainer = styled.div(
  ({ theme: { laptop } }) => css`
    display: block;
    border-bottom: 1px solid #1a1e24;

    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(10px);

    padding: 10px 0;
    width: 100%;
    overflow: auto;
    position: sticky;
    top: var(--navbar-height);
    z-index: 20;
    ${hideScrollbarStyles};

    ${laptop()} {
      padding: 20px 0;
    }
  `
);

export const TableHeaderContent = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 12fr 10fr 10fr 10fr;
  min-width: 700px;
`;

export const TableHeaderText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
`;

export const TableHeaderLink = styled(Link)(
  ({ theme: { laptop } }) => css`
    position: relative;
    width: 100%;
    height: 32px;
    border-radius: 8px;
    background-color: #18181b;
    transition: background-color 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 15px 5px 15px;
    cursor: pointer;

    ${TableHeaderText} {
      &:first-child {
        opacity: 1;
      }

      &:last-child {
        opacity: 0;
      }
    }

    ${laptop()} {
      height: 38px;
      align-items: flex-start;
    }

    &:hover {
      background-color: #26262d;

      ${TableHeaderText} {
        &:first-child {
          opacity: 0;
        }

        &:last-child {
          opacity: 1;
        }
      }
    }
  `
);
