import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { SiteLayout } from '../components/Layout/SiteLayout';
import { PlansScreen, PlansComparisonScreen, EnterpriseScreen } from '../components/forPricing';
import { FAQScreen, FAQ_CONTENT } from '../components/forPricing/FAQScreen';
import { SmallCTA } from '../components/SmallCTA';

const getFaqContent = () => {
  return FAQ_CONTENT.map(({ question, answer }) => ({
    '@type': 'Question',
    name: question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: answer,
    },
  }));
};

const schema = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  name: 'Pricing',
  mainEntity: getFaqContent(),
};

export const frontmatter = {
  title: 'Pricing',
  description: 'Flexible pricing for companies and individual developers.',
};

export const plansQuery = graphql`
  {
    plans(availablePlans: {}) {
      availablePlans {
        id
        name
        description
        price
        features {
          operations {
            title
            description
            is_enabled
            is_addon
            price
            unit_name
            is_gauge
            is_operation
            soft_limit
            hard_limit
            extra_units_volume
            extra_price
          }
          uploads {
            description
            extra_units_volume
            filesize_limit
            soft_limit
            is_enabled
            is_gauge
            normalized_unit_name
            price
            title
            unit_name
          }
          adaptive_delivery {
            description
            is_enabled
            price
            title
          }
          traffic {
            description
            extra_price
            extra_units_volume
            hard_limit
            is_enabled
            is_gauge
            normalized_unit_name
            price
            soft_limit
            title
            unit_name
          }
          storage {
            description
            extra_price
            extra_units_volume
            hard_limit
            is_enabled
            is_gauge
            normalized_unit_name
            price
            soft_limit
            title
            unit_name
          }
          aws_rekognition_detect_labels {
            is_enabled
            title
            unit_name
            is_addon
            is_gauge
          }
          remove_bg {
            is_addon
            is_enabled
            is_gauge
            price
            title
            unit_name
          }
          video_conversion {
            is_addon
            is_enabled
            is_gauge
            price
            title
            unit_name
          }
          custom_cdn_cnames {
            extra_price
            is_addon
            is_enabled
            price
            is_gauge
            title
            unit_name
            soft_limit
          }
          custom_upload_cnames {
            extra_price
            is_addon
            is_enabled
            price
            is_gauge
            title
            unit_name
            soft_limit
          }
          document_conversion {
            is_addon
            is_enabled
            is_gauge
            price
            title
            unit_name
          }
          virus_checking {
            is_addon
            is_enabled
            price
            title
          }
          team_members {
            is_enabled
            limit
            title
            price
            is_addon
          }
          secure_delivery {
            title
            is_enabled
            price
            is_addon
          }
          secure_uploads {
            is_enabled
            price
            title
            is_addon
          }
          custom_storage {
            title
            price
            is_addon
            is_enabled
          }
          backup {
            is_addon
            is_enabled
            price
            title
          }
          mime_type_filtering {
            is_addon
            is_enabled
            title
          }
          custom_oauth_apps {
            is_addon
            is_enabled
            title
          }
          avif_image_optimization {
            title
            is_addon
            is_enabled
          }
          webhooks {
            is_addon
            is_enabled
            price
            title
          }
          usage_info {
            is_addon
            is_enabled
            title
          }
          white_labeling {
            is_enabled
            price
            title
          }
        }
      }
    }
  }
`;

const Pricing = ({ location }) => {
  const { plans: data } = useStaticQuery(plansQuery) ?? {};

  return (
    <SiteLayout meta={{ ...frontmatter, schema }} pathName={location.pathname} isDark>
      <PlansScreen plans={data.availablePlans} />
      <EnterpriseScreen />
      <PlansComparisonScreen plans={data.availablePlans} />
      <FAQScreen />

      <SmallCTA
        btnSignUpProps={{ 'data-analytics': 'btn_signup_5' }}
        btnScheduleDemoProps={{
          'data-analytics': 'btn_schedule-demo_4',
        }}
      />
    </SiteLayout>
  );
};

export default Pricing;
