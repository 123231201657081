import React, { useRef } from 'react';

import { useSyncScroll } from '~/src/hooks/useSyncScroll';
import {
  PlansComparisonScreenSection,
  PlansComparisonScreenHeading,
  PlansComparisonTable,
  FeatureSection,
  FeatureSectionTitle,
  FeatureSubsection,
  FeatureSubsectionSubtitle,
  TableWrapper,
} from './styles/PlansComparisonScreen.styles';

import { FEATURES_SECTIONS } from './data/data';
import { PLANS_ENUM } from '../data';

import { UsageSection, FeatureRow, SupportRow, TableHeader } from './components';

const featuresMap = (name, { title, property, tooltipText, featureEnabledPlans }, plans = []) => (
  <FeatureRow
    sectionName={name}
    key={`${name}-${title}`}
    title={title}
    property={property}
    tooltipText={tooltipText}
    featureEnabledPlans={featureEnabledPlans}
    plans={plans}
  />
);

export const PlansComparisonScreen = ({ plans = [] }) => {
  const contentRef = useRef(null);
  const headerRef = useRef(null);

  useSyncScroll(contentRef, headerRef);

  if (plans.length === 0) {
    return null;
  }

  return (
    <PlansComparisonScreenSection as="section" data-navbar-theme="dark">
      <PlansComparisonScreenHeading>Compare plans</PlansComparisonScreenHeading>
      <TableWrapper>
        <TableHeader plans={plans} ref={headerRef} />
        <PlansComparisonTable ref={contentRef}>
          <UsageSection plans={plans} />
          {FEATURES_SECTIONS.map(({ name, features = [], subsections = [] }) => (
            <FeatureSection key={name}>
              <FeatureSectionTitle>{name}</FeatureSectionTitle>
              {features.map((feature) => featuresMap(name, feature, plans))}
              {subsections.map(({ name, features }) => (
                <FeatureSubsection key={name}>
                  <FeatureSubsectionSubtitle>{name}</FeatureSubsectionSubtitle>
                  {features.map((feature) => featuresMap(name, feature, plans))}
                </FeatureSubsection>
              ))}
            </FeatureSection>
          ))}
          <FeatureSection>
            <FeatureSectionTitle>Support</FeatureSectionTitle>
            <FeatureRow
              sectionName="Support"
              title="Community support"
              tooltipText="Get help from Uploadcare team and community members through our Forum and Discord."
              plans={plans}
            />
            <FeatureRow
              sectionName="Support"
              title="Email support"
              tooltipText="Get help from Uploadcare support engineers."
              featureEnabledPlans={[PLANS_ENUM.PRO, PLANS_ENUM.BUSINESS]}
              plans={plans}
            />
            <FeatureRow
              sectionName="Support"
              title="Priority email support"
              tooltipText="Get help from Uploadcare support engineers with a guaranteed response time of 1 business day."
              featureEnabledPlans={[PLANS_ENUM.BUSINESS]}
              plans={plans}
            />
            <SupportRow
              sectionName="Support"
              title="Email support SLA"
              tooltipText="Get custom support response time SLA."
              plans={plans}
            />
            <SupportRow
              sectionName="Support"
              title="Integration assistance"
              tooltipText="Our experts will help you with your integration process."
              plans={plans}
            />
            <SupportRow
              sectionName="Support"
              title="Private Slack channel"
              tooltipText="Get support from Uploadcare engineers and developers through a private Slack channel."
              plans={plans}
            />
          </FeatureSection>
        </PlansComparisonTable>
      </TableWrapper>
    </PlansComparisonScreenSection>
  );
};
