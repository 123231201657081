export const FAQ_CONTENT = [
  {
    question: 'What are Operations?',
    answer:
      '<p>' +
      'Operations are various actions associated with your files and API usage.' +
      ' Actions like uploading a file, making requests, or requesting transformations' +
      ' on your images are considered operations. Different actions have different operation' +
      ' costs, If you’re curious about more information on how operations are calculated you can read' +
      ' <a href="https://uploadcare.com/docs/billing/">this article</a>.' +
      '</p>',
  },
  {
    question: 'What counts as Traffic?',
    answer:
      '<p>' +
      'In Uploadcare, &quot;Traffic&quot; refers to the amount of data transferred from' +
      ' Uploadcare’s servers toward your end-users, especially when you fetch files via Uploadcare’s' +
      ' Content Delivery Network (CDN). Every time an image, video, or any other file is retrieved' +
      ' and delivered to your users, that data transfer is counted as CDN traffic.' +
      '</p>' +
      '<br />' +
      '<p>' +
      'Uploadcare offers image and video optimization tools, which are quite handy because' +
      ' they help you significantly reduce your traffic usage. By optimizing your files, you’re' +
      ' essentially reducing the file size without noticeably affecting the quality, meaning less' +
      ' data needs to be transferred, hence, less traffic used.' +
      '</p>',
  },
  {
    question: 'What counts as Storage?',
    answer:
      '<p>' +
      'When it comes to Storage in Uploadcare, it’s all about the total size of the original files' +
      ' stored in your account. This includes every single image, video, document, or other type of file' +
      ' that you’ve uploaded to Uploadcare. Unlike in some other systems, only the original versions of' +
      ' your files count towards your storage — not any transformations or alterations you might have made.' +
      '</p>' +
      '<br />' +
      '<p>' +
      'It’s important to note that this metric is cumulative, meaning it adds up over time.' +
      ' The storage counter doesn’t reset at the start of a new billing cycle.' +
      '</p>',
  },
  {
    question: 'Can I use the Demo plan for free?',
    answer:
      '<p>' +
      'Yes, the Demo plan is free of charge. It is designed to allow users to' +
      ' explore and experience the features and capabilities of Uploadcare' +
      ' without any cost. However, it’s important to note that the Demo plan may' +
      ' have certain limitations compared to our paid subscription plans. If you' +
      ' require additional information about the specific features and' +
      ' limitations of the Demo plan, feel free to contact Uploadcare’s' +
      ' <a href="https://uploadcare.com/schedule-demo/">sales team</a>.' +
      '</p>',
  },
  {
    question: 'What happens if my Demo plan limit is reached?',
    answer:
      '<p>' +
      'The Demo plan is meant to give you a taste of Uploadcare’s features for' +
      ' free, but going over the limits will result in a suspension until the' +
      ' next month begins. To avoid any interruptions in your account, we' +
      ' suggest upgrading to one of our paid plans. This will provide you with' +
      ' more generous limits and ensure uninterrupted usage.' +
      '</p>',
  },
  {
    question: 'How do you charge me? When will I get billed?',
    answer:
      '<p>' +
      'We charge for our services through a subscription model. There are two' +
      ' types of fees: Subscription Fees and Usage Fees. The Subscription Fee' +
      ' is paid upfront when you sign up and covers a certain amount of usage' +
      ' for the chosen monthly or annual period.' +
      '</p>' +
      '<br />' +
      '<p>' +
      'If you exceed the allowed usage limits in your subscription plan, we' +
      ' will charge Usage Fees at the end of each month.' +
      '</p>' +
      '<br />' +
      '<p>' +
      'For more detailed information on the terms and conditions, including' +
      ' the Order Form, taxes, and refund policies, please refer to the full' +
      ' <a href="https://uploadcare.com/about/terms/">terms of service</a>' +
      ' provided by Uploadcare.' +
      '</p>',
  },
  {
    question: 'What happens if I go over my paid plan’s limits?',
    answer:
      '<p>' +
      'Uploadcare operates on a soft limits basis, this means you won’t be' +
      ' immediately cut off or charged extra fees without warning. Instead,' +
      ' Uploadcare will notify you and ask that you consider upgrading to a' +
      ' plan better suited to your usage needs.' +
      '</p>' +
      '<br />' +
      '<p>' +
      'It’s a friendly reminder, to give you the chance to assess your needs' +
      ' and make the right decision for your project. If you have more' +
      ' specific questions or require assistance to understand your usage and' +
      ' select an appropriate plan, feel free to contact Uploadcare’s' +
      ' <a href="https://uploadcare.com/schedule-demo/">sales team</a>.' +
      '</p>',
  },
  {
    question: 'Which payment methods do you support?',
    answer:
      '<p>' +
      'Uploadcare currently accepts major credit and debit cards for payment.' +
      ' In addition, if you’re subscribed to the Enterprise plan, you also' +
      ' have the option to make payments via bank transfers.' +
      '</p>' +
      '<br />' +
      '<p>' +
      'Please note, alternatives like PayPal, Bitcoin, and similar methods' +
      ' are not currently supported. If you need further information or' +
      ' assistance with payment methods, feel free to get in touch with Uploadcare’s' +
      ' <a href="https://uploadcare.com/schedule-demo/">sales team</a>.' +
      '</p>',
  },
  {
    question: 'Who has ownership of the files and data uploaded to Uploadcare?',
    answer:
      '<p>' +
      'Uploadcare values your ownership rights and privacy. All files and data' +
      ' you upload to Uploadcare remain your property. You maintain ownership of' +
      ' your files and data, and Uploadcare merely acts as a service provider to' +
      ' store and handle your files based on your instructions.' +
      '</p>',
  },
  {
    question: 'Can Uploadcare store sensitive files and data?',
    answer:
      '<p>' +
      'Yes, Uploadcare is SOC2 and HIPAA-compliant, ensuring that' +
      ' industry-standard security measures are in place to protect your files' +
      ' and data. As a trusted file management service, Uploadcare prioritizes' +
      ' the security and integrity of your information.' +
      '</p>' +
      '<br />' +
      '<p>' +
      'Uploadcare provides a 99.9% Service Level Agreement (SLA) on all paid' +
      ' plans, ensuring high availability and reliability of the services.' +
      ' Additionally, Uploadcare systems maintain over 99.99% monthly uptime' +
      ' figures for most regions.' +
      '</p>' +
      '<br />' +
      '<p>' +
      'For more specific details about Uploadcare’s security certifications,' +
      ' compliance standards, and the SLA, check out the' +
      ' <a href="https://uploadcare.com/about/trust/">Trust Center</a>.' +
      '</p>',
  },
  {
    question: 'Any special deals for startups, schools, or non-profit groups?',
    answer:
      '<p>' +
      'Yes, Whether you’re a startup, school, or non-profit group, Uploadcare' +
      ' aims to support your initiatives and provide' +
      ' <a href="https://uploadcare.com/hub/startups/">the startup program</a>,' +
      ' that fit your requirements. To explore the specific deals and discounts' +
      ' available, we recommend getting in touch with Uploadcare’s' +
      ' <a href="https://uploadcare.com/schedule-demo/">sales team</a>.' +
      '</p>',
  },
];
