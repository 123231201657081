export const formatMoney = (value, extraOptions) => {
  value = Number(value);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 3,
    ...extraOptions,
  });

  return formatter.format(value);
};

export const formatNumber = (value) => new Intl.NumberFormat('en-US').format(value);
