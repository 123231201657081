import React, { useContext } from 'react';

import { TooltipStyledButton, TooltipButtonIcon, TooltipButtonCheckbox } from '~/src/ui/Tooltip';
import { TooltipContext } from '../Tooltip';

export const TooltipButton = ({ className, children }) => {
  const id = useContext(TooltipContext);

  return (
    <TooltipStyledButton className={className} data-tooltip-button={id}>
      {children ?? <TooltipButtonIcon />}
      <TooltipButtonCheckbox />
    </TooltipStyledButton>
  );
};
