import React from 'react';
import { formatNumber } from '@uc-common/utils';

import { prettyBytes } from '~/src/utils/formatting';
import { getTeamMembers } from '~/src/components/forPricing/utils';
import {
  FeatureForPlan,
  FeatureSection,
  FeatureSectionTitle,
  TableRowContent,
  TableRowTitle,
  TableRowTitleTooltipButton,
} from '../../styles/PlansComparisonScreen.styles';

export const UsageSection = ({ plans }) => (
  <FeatureSection>
    <FeatureSectionTitle>Usage</FeatureSectionTitle>
    <TableRowContent>
      <TableRowTitle
        id="operations-tooltip"
        tooltipText="Operations include file and API-related actions such as file uploads, requests, and image transformations."
      >
        <TableRowTitleTooltipButton />
        Operations
      </TableRowTitle>

      {plans.map(
        ({
          id,
          features: {
            operations: { is_enabled: isEnabled, soft_limit: softLimit },
          },
        }) => (
          <FeatureForPlan key={`operations${id}`}>
            {isEnabled ? formatNumber(softLimit) || 'Unlimited' : 'Not available'}
          </FeatureForPlan>
        )
      )}
    </TableRowContent>

    <TableRowContent>
      <TableRowTitle
        id="traffic-tooltip"
        tooltipText="Deliver your media anywhere as if it was cached next door."
      >
        <TableRowTitleTooltipButton />
        Traffic
      </TableRowTitle>

      {plans.map(
        ({
          id,
          features: {
            traffic: { is_enabled: isEnabled, soft_limit: softLimit },
          },
        }) => (
          <FeatureForPlan key={`operations${id}`}>
            {isEnabled ? prettyBytes(softLimit) || 'Unlimited' : 'Not available'}
          </FeatureForPlan>
        )
      )}
    </TableRowContent>

    <TableRowContent>
      <TableRowTitle
        id="storage-tooltip"
        tooltipText="Fast and secure integrated storage that just works."
      >
        <TableRowTitleTooltipButton />
        Storage
      </TableRowTitle>

      {plans.map(
        ({
          id,
          features: {
            storage: { is_enabled: isEnabled, soft_limit: softLimit },
          },
        }) => (
          <FeatureForPlan key={`storage${id}`}>
            {isEnabled ? prettyBytes(softLimit) || 'Unlimited' : 'Not available'}
          </FeatureForPlan>
        )
      )}
    </TableRowContent>

    <TableRowContent>
      <TableRowTitle
        id="max-file-size-tooltip"
        tooltipText="Maximum file size Uploadcare will accept."
      >
        <TableRowTitleTooltipButton />
        Max file size
      </TableRowTitle>

      {plans.map(
        ({
          id,
          features: {
            uploads: { filesize_limit: fileSizeLimit },
          },
        }) => (
          <FeatureForPlan key={`file-size-limit${id}`}>
            {Number(fileSizeLimit) ? prettyBytes(fileSizeLimit) : 'Custom'}
          </FeatureForPlan>
        )
      )}
    </TableRowContent>

    <TableRowContent>
      <TableRowTitle
        id="team-members-tooltip"
        tooltipText="Maximum number of accounts you can share project with."
      >
        <TableRowTitleTooltipButton />
        Team members
      </TableRowTitle>

      {plans.map(({ id, features: { team_members: teamMembers } }) => (
        <FeatureForPlan key={`team-members${id}`}>{getTeamMembers(teamMembers)}</FeatureForPlan>
      ))}
    </TableRowContent>
  </FeatureSection>
);
