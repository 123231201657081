import styled, { css } from 'styled-components';
import { Container as Wrapper } from '@uc-common/container';

import { BigHeading } from '../../Typography';

export const PlansScreenSection = styled(Wrapper)(
  ({ theme: { tablet } }) => css`
    padding-top: 60px;

    ${tablet()} {
      padding-top: 75px;
    }
  `
);

export const PlansScreenHeading = styled(BigHeading)`
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  text-wrap: balance;
`;

export const PlansScreenSubText = styled.p(
  ({ theme: { tablet, laptop, desktop } }) => css`
    max-width: 480px;
    font-size: 18px;
    margin: 20px auto 50px;
    font-weight: 500;
    line-height: 1.6;
    text-align: center;
    color: #bbc4d2;

    ${tablet()} {
      font-size: 20px;
      margin-bottom: 60px;
    }

    ${laptop()} {
      max-width: 750px;
    }

    ${desktop()} {
      max-width: 800px;
      margin-bottom: 80px;
    }
  `
);

export const PlansScreenCardsContainer = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;

    ${tablet()} {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 25px;
      padding-bottom: 60px;
    }

    ${laptop()} {
      padding-bottom: 60px;
    }
  `
);
