import React from 'react';
import { ButtonTypeEnum } from '@uc-common/button';

import { PLANS_ENUM, PROMOTED_PLAN } from '~/src/components/forPricing/data';
import { Link } from '~/src/components/Link';
import { toSnakeCase, isDefined } from '~/src/utils/utils';
import { getLimitsInfo } from '~/src/components/forPricing/PlansScreen/components/PlanCard/components/LimitsInfo';
import { ReactComponent as QuestionInfoIcon } from './assets/question-info.svg';
import { getMarketingPrice } from './utils';
import {
  PlanCardContainer,
  PlanCardButton,
  PlanCardHeading,
  PlanCardParagraph,
  PlansCardList,
  PlanCardSticker,
  PlanCardLimitsInfo,
  PlansCardListTitle,
  PlanCardBottom,
  PlanCardPriceWrapper,
  PlanCardTooltip,
  PlanCardTooltipHeading,
  PlanCardTooltipButton,
  PlanCardTooltipHoverZone,
} from './styles/PlanCard.styles';

export const PlanCard = ({ name, description, price, features }) => {
  const isPromotedPlan = name === PROMOTED_PLAN;
  const isBusinessPlan = name === PLANS_ENUM.BUSINESS;

  const limitsInfo = isDefined(features) ? getLimitsInfo({ features }) : '';
  const splittedLimitsInfo = limitsInfo.split(', ');
  const operationsInfoIndex = splittedLimitsInfo.findIndex((item) => item.includes('operations'));
  const limitsInfoWithoutOperations = splittedLimitsInfo.slice();
  limitsInfoWithoutOperations.splice(operationsInfoIndex, 1);

  return (
    <PlanCardContainer>
      {isPromotedPlan && <PlanCardSticker>Most Popular</PlanCardSticker>}
      <PlanCardHeading>{name}</PlanCardHeading>
      <PlanCardPriceWrapper>{getMarketingPrice(Number(price))}</PlanCardPriceWrapper>
      {Boolean(description) && <PlanCardParagraph>{description}</PlanCardParagraph>}

      <PlanCardButton
        forwardedAs={Link}
        to="/accounts/signup/"
        type={isPromotedPlan ? ButtonTypeEnum.PRIMARY : ButtonTypeEnum.TRANSPARENT}
        data-analytics={`btn_pricing_${toSnakeCase(name)}_signup`}
        ghost={!isPromotedPlan}
      >
        {price === 0 ? 'Get started' : 'Start for free'}
      </PlanCardButton>

      <PlansCardListTitle>
        {price === 0
          ? 'Features included:'
          : isBusinessPlan
          ? 'Everything in Pro, plus…'
          : 'Everything in Demo, plus…'}
      </PlansCardListTitle>

      <PlansCardList
        planName={name}
        teamMembers={features.team_members.limit}
        uploads={features.uploads}
      />

      <PlanCardBottom>
        <PlanCardLimitsInfo>
          {operationsInfoIndex >= 0 && (
            <PlanCardTooltip
              id="price-block-tooltip"
              tooltipText={
                <div>
                  <PlanCardTooltipHeading>Operations</PlanCardTooltipHeading>
                  Number of all newly processed files including original uploads, derivatives, and
                  API requests monthly.
                </div>
              }
            >
              <PlanCardTooltipHoverZone>
                <p>{splittedLimitsInfo[operationsInfoIndex]}</p>
                <PlanCardTooltipButton>
                  <QuestionInfoIcon />
                </PlanCardTooltipButton>
                ,{' '}
              </PlanCardTooltipHoverZone>
            </PlanCardTooltip>
          )}
          {limitsInfoWithoutOperations.join(', ')}
        </PlanCardLimitsInfo>
      </PlanCardBottom>
    </PlanCardContainer>
  );
};
