import React from 'react';

import { PLANS_ENUM, PROMOTED_PLAN } from '~/src/components/forPricing/data';
import { prettyBytes } from '~/src/utils/formatting';

import { FeaturesListContainer } from './styles/FeaturesList.styles';

const getPlanMarketingFeatures = (planName, uploads = {}, teamMemberLimit = 0) => {
  const defaultFeatures =
    planName === PLANS_ENUM.FREE
      ? [
          `${prettyBytes(uploads.filesize_limit)} max file size`,
          'Uploading widget, API',
          'Basic image transformations',
          'Delivery through a CDN',
          'Signed uploads',
          'Webhooks',
        ]
      : planName === PLANS_ENUM.BUSINESS
      ? [
          `${prettyBytes(uploads.filesize_limit)} max file size`,
          'Automated AVIF compression',
          'Uploading widget whitelabeling',
          'Custom Upload API domain',
          'Custom OAuth applications',
          'Dedicated solution engineer',
          'Performance & security review',
        ]
      : [
          `${prettyBytes(uploads.filesize_limit)} max file size`,
          'Auto-moderation on upload',
          'Malicious content filtering',
          'Automated image optimization',
          'Content-aware crop and resize',
          'Automated responsive images',
          'Document conversion',
          'Video processing',
          'Custom CDN CNAME support',
          'Signed URLs',
        ];

  teamMemberLimit > 1
    ? defaultFeatures.push(`${teamMemberLimit + 1} team members`)
    : defaultFeatures.push('Personal use only');

  return defaultFeatures;
};

export const FeaturesList = ({ className, planName, uploads = {}, teamMembers = {} }) => {
  const marketingFeatures = getPlanMarketingFeatures(planName, uploads, teamMembers);

  return (
    <FeaturesListContainer
      className={className}
      items={marketingFeatures}
      $isPromotedPlan={planName === PROMOTED_PLAN}
    />
  );
};
